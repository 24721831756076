<template>
  <div>
    <div class="content-section introduction">
      <div class="feature-intro text-h6 font-weight-light">
        {{ tLabel("Pre-Advice/Entrance Create") | uppercase }}
        <v-divider />
      </div>
    </div>

    <v-form ref="createPreadviceForm">
      <v-container fluid>
        <v-row align="center">
          <v-col cols="12">
            <v-card rounded="0" color="panelheader" >
              <v-card-text>
                <v-row align="center">
                  <v-col cols="2" class="text-h6 text-uppercase d-flex align-center">
                    <v-checkbox v-model="preadviceSubmitReqObj.isHandlingInAdvised" /> {{ tLabel("Advised") }}
                  </v-col>
                  <v-col cols="3">
                    <DateTimeAdapter
                      :label="tLabel('Planned Date / Time')"
                      v-model="preadviceSubmitReqObj.handlingInPlannedDate"
                      :disabled="preadviceSubmitReqObj.isHandlingInAdvised !== true"
                      :textFieldBackgroundColor="preadviceSubmitReqObj.isHandlingInAdvised === true ? 'white' : null"
                    />
                  </v-col>
                  <v-col cols="7" class="text-right">
                    <v-icon size="60" color="secondary">{{ preadviceSubmitReqObj.isHandlingInAdvised === true ? 'mdi-clock-fast' : 'mdi-login-variant' }}</v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card rounded="0">
              <v-card-title class="text-uppercase font-weight-regular py-2 my-2 panelheader">
                <v-checkbox v-model="preadviceSubmitReqObj.doHandlingIn" @click="onClickDoHandlingIn" />{{ tLabel("Entrance / Entrance Pre-Advice") }}
              </v-card-title>
              <v-card-text v-if="preadviceSubmitReqObj.doHandlingIn === true">
                <v-container fluid>
                  <v-row align="center">
                    <v-col cols="12">
                      <v-card rounded="0" elevation="0">
                        <v-card-title class="text-uppercase font-weight-light grey lighten-5">
                          <v-icon large class="mr-2">mdi-truck</v-icon> {{ tLabel("Transport Vehicle") }}
                          <v-spacer />
                          <span v-if="truckExistsMsg !== null" class="text-h6 text-uppercase font-weight-bold warning pa-1"> {{ truckExistsMsg }} </span>
                        </v-card-title>
                        <v-divider class="warning"></v-divider>
                        <v-card-text>
                          <v-row align="center">
                            <v-col cols="4">
                              <v-text-field
                                :label="tLabelTruckDriverRequired('License Plate')"
                                clearable
                                v-model="preadviceSubmitReqObj.handlingInCarrierLicensePlate"
                                @change="onChangeCarrierLicensePlate"
                                :rules="truckDriverRequired"
                                @input="(val) => (preadviceSubmitReqObj.handlingInCarrierLicensePlate = val ? val.toUpperCase() : null)"
                              />
                            </v-col>
                            <v-col cols="4">
                              <v-autocomplete
                                :label="tLabel('Transport Company')"
                                v-model="preadviceSubmitReqObj.handlingInCarrierOwnerCompanyId"
                                :items="carriers"
                                item-value="id"
                                item-text="code"
                                :key="carrierCompanyKey"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="12">
                      <v-card rounded="0" elevation="0">
                        <v-card-title class="text-uppercase font-weight-light grey lighten-5"> <v-icon large class="mr-2">mdi-account</v-icon>{{ tLabel("Driver") }} </v-card-title>
                        <v-divider class="warning"></v-divider>
                        <v-card-text>
                          <v-row align="center">
                            <v-col>
                              <v-combobox
                                :label="tLabelTruckDriverRequired('Surname')"
                                v-model="driver"
                                @change="changeDriver"
                                :items="drivers"
                                :search-input.sync="searchDriver"
                                :loading="isLoadingDrivers"
                                return-object
                                item-text="surname"
                                :rules="truckDriverRequired"
                              >
                                <template v-slot:item="{ index, item }">
                                  <v-list-item-content>
                                    <v-list-item-title> {{ item.surname }} {{ item.name }} </v-list-item-title>
                                    <v-list-item-subtitle>
                                      <span class="company">{{ item_with_label(item.owner, "Company") }}</span>
                                      <span class="identityDocNumber">{{ item_with_label(item.identityDocNumber, "Identity doc number") }}</span>
                                      <span class="driverLicence">{{ item_with_label(item.driverLicence, "Driver license") }}</span>
                                      <span class="badge">{{ item_with_label(item.badge, "Badge") }}</span>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-combobox>
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                :label="tLabelTruckDriverRequired('Name')"
                                v-model="preadviceSubmitReqObj.handlingInDriverName"
                                :rules="truckDriverRequired"
                                @input="(val) => (preadviceSubmitReqObj.handlingInDriverName = val ? val.toUpperCase() : null)"
                              />
                            </v-col>
                            <v-col cols="3">
                              <v-autocomplete
                                :label="tLabel('Company')"
                                v-model="preadviceSubmitReqObj.handlingInDriverCompanyId"
                                :items="carriers"
                                item-value="id"
                                item-text="code"
                              />
                            </v-col>
                          </v-row>
                          <v-row align="center">
                            <v-col cols="4">
                              <v-text-field
                                :label="tLabel('Identity doc number')"
                                v-model="preadviceSubmitReqObj.handlingInDriverIdentityDocNumber"
                                @input="(val) => (preadviceSubmitReqObj.handlingInDriverIdentityDocNumber = val ? val.toUpperCase() : null)"
                              />
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                :label="tLabel('Driver license')"
                                v-model="preadviceSubmitReqObj.handlingInDriverDriverLicense"
                                @input="(val) => (preadviceSubmitReqObj.handlingInDriverDriverLicense = val ? val.toUpperCase() : null)"
                              />
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                :label="tLabel('Badge')"
                                v-model="preadviceSubmitReqObj.handlingInDriverBadge"
                                @input="(val) => (preadviceSubmitReqObj.handlingInDriverBadge = val ? val.toUpperCase() : null)"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="12">
                      <v-card rounded="0" elevation="0">
                        <v-card-title class="text-uppercase font-weight-light grey lighten-5">
                          <v-icon large class="mr-2">mdi-file-document-edit</v-icon>{{ tLabel("Note ingresso") }}
                        </v-card-title>
                        <v-divider class="warning"></v-divider>
                        <v-card-text>
                          <v-row align="center">
                            <v-col cols="12">
                              <v-textarea v-model="preadviceSubmitReqObj.handlingInRemarks" counter maxlength="255" />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-text v-else> </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card rounded="0">
              <v-tabs v-model="tabGoods" left background-color="panelheader" height="82">
                <v-tab class="text-h6"> <v-checkbox v-model="preadviceSubmitReqObj.doDropOffUnit" @click="onClickDropOff" /> {{ tLabel("Drop-off") }} </v-tab>
                <v-tab class="text-h6" v-if="preadviceSubmitReqObj.doHandlingIn === true">
                  <v-checkbox v-model="preadviceSubmitReqObj.doPickUpUnit" @click="onClickPickUp" />{{ tLabel("Pick-up") }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tabGoods" touchless>
                <v-tab-item>
                  <v-container fluid>
                    <v-row align="center">
                      <v-col cols="12" v-if="preadviceSubmitReqObj.doDropOffUnit === true">
                        <CargoesComposePanel :cargoes="preadviceSubmitReqObj.units" />
                      </v-col>
                      <v-col cols="12" v-else>
                        <span class="text-uppercase font-weight-light text-h4 text-left">{{ tLabel("No drop-off to be made.") }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item>
                  <v-container fluid>
                    <v-row align="center">
                      <v-col cols="12" v-if="preadviceSubmitReqObj.doPickUpUnit === true">
                        <CargoesSearchAndSelectionPanel :cargoes="pickUpUnits" :cargoType="pickUpCargoType" @cargoTypeChanged="changePickupCargoType" />
                      </v-col>
                      <v-col cols="12" v-else>
                        <span class="text-uppercase font-weight-light text-h4 text-left">{{ tLabel("No pick-up to be made.") }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>

              <v-tabs color="primary" v-model="tabGoods" right background-color="panelheader">
                <v-tab class="text-h6">{{ tLabel("Drop-off") }}</v-tab>
                <v-tab class="text-h6" v-if="preadviceSubmitReqObj.doHandlingIn === true">{{ tLabel("Pick-up") }}</v-tab>
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" style="text-align: right">
          <v-btn medium outlined class="pa-2 ma-2" @click="discardVisits"> <v-icon class="mr-2">mdi-cancel</v-icon>{{ tLabel("Cancel") }} </v-btn>
          <v-btn color="primary" class="pa-2 ma-2" @click="saveVisits(false)" medium :disabled="saveDisabled" v-if="preadviceSubmitReqObj.isHandlingInAdvised">
            <v-icon class="mr-2">mdi-content-save</v-icon>{{ tLabel("Salva") }}
          </v-btn>
          <v-btn color="primary" class="pa-2 ma-2" @click="saveVisits(false)" medium :disabled="saveDisabled" v-if="!preadviceSubmitReqObj.isHandlingInAdvised">
            <v-icon class="mr-2">mdi-login-variant</v-icon>{{ tLabel("Gate-in") }}
          </v-btn>
          <v-btn color="primary" class="pa-2 ma-2" @click="saveVisits(true)" medium :disabled="saveDisabled" v-if="!preadviceSubmitReqObj.isHandlingInAdvised">
            <v-icon class="mr-2">mdi-printer-outline</v-icon>{{ tLabel("Gate-in + Stampa") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import YardEnumMixin from "../../../mixins/yard/EnumMixin";
import YardMixin from "../../../mixins/yard/YardMixin";
import FormatsMixins from "../../../mixins/FormatsMixins";
import ToastMixin from "../../../mixins/ToastMixin";
import DateTimeAdapter from "../../../components/anagrafiche/DateTimeAdapter";
import CargoesComposePanel from "@/components/yard/CargoesComposePanel";
import CargoesSearchAndSelectionPanel from "@/components/yard/CargoesSearchAndSelectionPanel";

export default {
  name: "CreatePreadvice",
  data() {
    return {
      tabGoods: null,
      preadviceSubmitReqObj: {
        doDropOffUnit: true,
        units: [],
        doHandlingIn: true,
        isHandlingInAdvised: false
      },
      pickUpCargoType: null,
      pickUpUnits: [],
      truckExistsMsg: null,
      carriers: [],
      driver: null,
      drivers: [],
      searchDriver: null,
      isLoadingDrivers: false,
      carrierCompanyKey: 0,
    };
  },
  components: {
    DateTimeAdapter,
    CargoesComposePanel,
    CargoesSearchAndSelectionPanel,
  },
  mixins: [YardEnumMixin, FormatsMixins, ToastMixin, YardMixin],
  async mounted() {
    this.carriers = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/carriers");
  },
  computed: {
    saveDisabled() {
      return this.truckExistsMsg !== null || 
        (this.preadviceSubmitReqObj.doDropOffUnit === false && this.preadviceSubmitReqObj.doHandlingIn === false) ||
        (this.preadviceSubmitReqObj.doDropOffUnit === true && this.preadviceSubmitReqObj.units.length === 0) || 
        (this.preadviceSubmitReqObj.doPickUpUnit === true && this.pickUpUnits.length === 0);
    },
    truckDriverRequired() {
      if (this.preadviceSubmitReqObj.doHandlingIn && !this.preadviceSubmitReqObj.isHandlingInAdvised) return [this.rules.required];
      return [];
    },
  },
  watch: {
    async searchDriver(val, val2) {
      if (val !== null && val2 !== null && val.toUpperCase() === val2.toUpperCase()) return;
      val = val !== null ? val.toUpperCase() : null;
      this.searchDriver = val;
      this.drivers = [];
      if (val == null || val.length < 3 || this.isLoading) return;
      if (val === "NONE") return [];
      this.isLoadingDrivers = true;
      this.drivers = await this.$api.yardData.searchDrivers(val);
      this.isLoadingDrivers = false;
    },
  },
  methods: {
    changeDriver(item) {
      var obj = {};
      if (item && item !== null) {
        if (typeof item == "string") {
          obj = {
            handlingInDriverSurname: item,
          };
        } else {
          obj = {
            handlingInDriverSurname: item.surname,
            handlingInDriverName: item.name,
            handlingInDriverCompanyId: item.ownerCompanyId,
            handlingInDriverIdentityDocNumber: item.identityDocNumber,
            handlingInDriverDriverLicense: item.driverLicense,
            handlingInDriverBadge: item.badge,
          };
        }
      }
      Object.assign(this.preadviceSubmitReqObj, obj);
    },
    onClickDoHandlingIn() {
      if (this.preadviceSubmitReqObj.doHandlingIn === false) {
        this.preadviceSubmitReqObj.doPickUpUnit = false;
      }
    },
    async onChangeCarrierLicensePlate() {
      const plate = this.preadviceSubmitReqObj.handlingInCarrierLicensePlate;
      this.truckExistsMsg = null;
      try {
        const resp = await this.$api.yardData.preadvicePlate(plate);
        if (resp != null) {
          this.preadviceSubmitReqObj.handlingInCarrierOwnerCompanyId = resp.ownerCompanyId;
          this.carrierCompanyKey += 1;
        }
      } catch (e) {
        this.truckExistsMsg = this.tLabel(e.detailMessage);
      }
    },
    async saveVisits(print) {
      console.log("preadviceSubmitReqObj to save", this.preadviceSubmitReqObj);
      const valid = this.$refs.createPreadviceForm.validate();
      if (!valid) {
        this.showError("Uno dei campi obligatori non è stato inserito");
        console.log("valid", valid);
        return;
      }

      if (this.pickUpUnits && this.pickUpUnits.length > 0) {
        this.preadviceSubmitReqObj.pickUpUnitsIds = [];
        this.pickUpUnits.forEach((pickUpUnit) => {
          console.log("-----------", pickUpUnit);
          this.preadviceSubmitReqObj.pickUpUnitsIds.push(pickUpUnit.visitId);
        });
      }

      console.log("saveVisits preadviceSubmitReqObj modified to save", this.preadviceSubmitReqObj);
      try {
        const visitId = await this.$api.yardData.preadviceSubmit(this.preadviceSubmitReqObj);
        if (!this.preadviceSubmitReqObj.isHandlingInAdvised && print) {
          this.stampaInterchange(visitId);
        }
        this.$router.go(-1);
      } catch (e) {
        this.showError("Operazione non riuscita. " + e.detailMessage);
      }
    },
    discardVisits() {
      console.log("discard", this.preadviceSubmitReqObj);
      this.$router.go(-1);
    },
    onClickDropOff() {
      if (this.preadviceSubmitReqObj.doDropOffUnit === false) {
        this.preadviceSubmitReqObj.isHandlingInAdvised = false;
        this.preadviceSubmitReqObj.handlingInPlannedDate = null;
      }
    },
    onClickPickUp() {
      if (this.preadviceSubmitReqObj.doPickUpUnit === false) {
        this.pickUpCargoType = null;
        this.pickUpUnits = new Array();
      }
    },
    async stampaInterchange(id) {
      //TODO Duplicato da preadvice
      try {
        const _callConfig = {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
            Accept: "*/*",
            Pragma: "no-cache",
          },
        };
        var url = `${this.$apiConfiguration.BASE_PATH_YARD}reports/interchangeInByVisitId/` + id;
        const response = await this.$api.get(url, _callConfig);
        const blob = new Blob([response], { type: "application/pdf;charset=ANSI" });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute("download", "Interchange3.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        console.error("Errore Interchange", e);
      }
    },
    item_with_label(item, label) {
      if (item == null) return;
      return this.tLabel(label) + ": " + item + ". ";
    },
    tLabelTruckDriverRequired(field) {
      if (this.truckDriverRequired.length > 0) return this.tLabelRequired(field);
      return this.tLabel(field);
    },
    changePickupCargoType(type) {
      console.log("changePickupCargoType", type);
      this.pickUpCargoType = null;
      if (type && type !== null) {
        this.pickUpCargoType = type;
      }
    },
  },
};
</script>

<style scoped>
.outer {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.outer div {
  float: none;
  height: 100%;
  margin: 0 1%;
  display: inline-block;
}
</style>
